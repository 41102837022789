<template>
  <path
    d="M7.5 4V10H5.5V4H7.5ZM5.5 24V18H7.5V24H5.5ZM8.5 14C8.5 15.11 7.61 16 6.5 16C6.1044 16 5.7178 15.8827 5.3889 15.6629C5.06 15.4432 4.8036 15.1308 4.6522 14.7654C4.5009 14.3999 4.4613 13.9978 4.5384 13.6098C4.6156 13.2219 4.8061 12.8655 5.0858 12.5858C5.3655 12.3061 5.7219 12.1156 6.1098 12.0384C6.4978 11.9613 6.8999 12.0009 7.2654 12.1522C7.6308 12.3036 7.9432 12.56 8.1629 12.8889C8.3827 13.2178 8.5 13.6044 8.5 14ZM19.5 6C23.92 6 27.5 9.58 27.5 14C27.5 18.42 23.92 22 19.5 22C15.9 22 12.86 19.62 11.85 16.35L9.5 14L11.85 11.65C12.86 8.38 15.9 6 19.5 6ZM19.5 8C16.19 8 13.5 10.69 13.5 14C13.5 17.31 16.19 20 19.5 20C22.81 20 25.5 17.31 25.5 14C25.5 10.69 22.81 8 19.5 8ZM18.5 15V10H20V14.2L23 16L22.18 17.26L18.5 15Z"
    :fill="fill"
  />
</template>

<script>
export default {
  name: "TimelineIcon",
  props: {
    fill: {
      type: String,
      default: "#70D0CE",
    },
  },
};
</script>
