<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" :fill="fill">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.2742 17.481V21.0901L11.2897 17.481H15.8159C16.8729 17.481 17.7325 16.6214 17.7325 15.5644V7.89769C17.7325 6.84065 16.8729 5.98102 15.8159 5.98102H4.31587C3.25883 5.98102 2.3992 6.84065 2.3992 7.89769V15.5644C2.3992 16.6214 3.25883 17.481 4.31587 17.481H5.2742ZM4.31587 7.89769H15.8159V15.5644H10.7587L7.19087 17.7053V15.5644H4.31587V7.89769Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.6492 2.14769H8.1492C7.09216 2.14769 6.23253 3.00731 6.23253 4.06436H17.7325C18.7896 4.06436 19.6492 4.92398 19.6492 5.98102V13.6477C20.7062 13.6477 21.5659 12.7881 21.5659 11.731V4.06436C21.5659 3.00731 20.7062 2.14769 19.6492 2.14769Z"
    />
  </svg>
</template>

<script>
export default {
  name: "ChatIcon",
  props: {
    fill: {
      type: String,
      default: "#70D0CE",
    },
  },
};
</script>
