<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.9155 9.41327L8.16877 7.54418C8.82029 6.76305 9.22466 5.72829 9.22466 4.59868C9.22466 2.15955 7.36918 0.175049 5.08973 0.175049C2.80979 0.175049 0.954407 2.15965 0.954407 4.59868C0.954407 7.0377 2.80988 9.02231 5.08934 9.02231C5.89852 9.02231 6.64874 8.76908 7.28017 8.34676L9.09587 10.2892C9.20459 10.4055 9.35262 10.4686 9.50066 10.4686C9.6487 10.4686 9.79673 10.4051 9.90545 10.2892C10.1425 10.0467 10.1426 9.65578 9.9155 9.41327ZM2.10889 4.59857C2.10889 2.84572 3.44121 1.41013 5.08924 1.41013C6.73766 1.41013 8.06002 2.84582 8.06002 4.59857C8.06002 6.35132 6.7277 7.78701 5.08963 7.78701C3.45118 7.78701 2.10889 6.36167 2.10889 4.59857Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: "LogoutIcon",
  props: {
    width: {
      type: [Number, String],
      default: 15,
    },
    height: {
      type: [Number, String],
      default: 15,
    },
    fill: {
      type: String,
      default: "#442D64",
    },
    viewBox: {
      type: [Number, String],
      default: "0 0 11 11",
    },
  },
};
</script>
